/*externi knihovny */
.Toastify__toast--info {
  background-color: #4285f4;
}

.Toastify__toast--success {
  background-color: #20c997;
}

.Toastify__toast--error {
  background-color: #dc3545;
}

.Toastify__toast-body {
  margin-left: 8px;
  white-space: pre-line;
}

.invalid-container > .jodit-container {
  border-color: red !important;
}

.jodit-dialog {
  max-width: 60%;
}

/*konec externi knihovnz*/

/*zabrani black rect pri focus na icon button materila ui*/
button:focus {
  outline: none !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.avatar {
  width: 40px;
  display: inline-flex !important;
  font-weight: 400;
  background-color: #4285f4 !important;
}

a:hover .avatar {
  color: #fff !important;
  background-color: #e91e63 !important;
}

.navbar__logo {
  max-width: 180px;
}

.navbar .dropdown-menu a {
  font-size: 1rem;
  font-weight: 400;
  padding-left: 30px;
  padding-right: 40px;
}

.navbar .dropdown-menu a:hover {
  color: #e91e63 !important;
}

/* overide md boostrap */
.blockquote {
  padding: 0.5rem 1rem;
  border-left: 0.25rem solid #eceeef;
}
.blockquote.text-right {
  border-right: 0.25rem solid #eceeef;
  border-left: none;
}
.blockquote .bq-title {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 400;
}
.blockquote p {
  padding: 0.2rem 0;
  font-size: 0.95rem;
}

.btn-primary {
  border-color: #4285f4;
}

.btn-primary .disabled,
.btn-primary:disabled {
  border-color: #4285f4;
}

.btn-primary:hover,
.btn-primary:hover {
  background-color: #0661f5 !important;
  border-color: #0661f5;
}

/* button:active {
  outline: none;
  border: none;
  } */
/*
.btn:focus,
.btn-primary:focus {
  outline: 0 !important;
  border-color: transparent;
  box-shadow: none;
}
*/
.btn {
  text-transform: initial;
  font-size: 0.95rem;
}

.relative {
  position: relative;
}

.error-tooltip,
.error-tooltip-editor {
  background-color: red;
  color: white;
  position: absolute;
  z-index: 5;
  max-width: 100%;
  top: 78%;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 0.8rem;
}

.error-tooltip-editor {
  top: 100%;
}

/* td {
  height: 50px !important;
} */

/* table.table-sm tr {
  height: 10px !important;
}

table.table-sm th,
table.table-sm td {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
} */

/*velikost sirky radku material ui textfield*/
.container input {
  height: 40px;
  padding: 0px 14px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 100vw !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100vw !important;
  }
}

.container label {
  height: 40px;
  top: -4px;
}

.container label[data-shrink="true"] {
  top: 0;
}

.email-control {
  width: 63%;
}
/*velikost sirky radku material ui textfield end*/

.insurance-card {
  width: 100%;
  height: 70px;
  cursor: pointer;
}

.insurance-logo {
  max-width: 55px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.readonly-item {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.3;
}

.readonly-item-copy {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.8;
  cursor: none;
}

.branch-card {
  width: 100%;
  height: 70px;
  cursor: pointer;
}

.branch-card-text {
  line-height: 1.2;
}

.hint {
  font-size: 0.95rem;
  font-weight: 400;
  font-style: italic;
  color: rgba(0, 0, 0, 0.65);
}

.ext-info-card {
  width: 100%;
  height: 70px;
  cursor: pointer;
}

.debug-info {
  font-size: 0.85rem;
}
.image-cropper {
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.cirular-img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/*
.image-cropper > img {
  object-fit: cover;
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}*/
.team-no-image {
  width: 200px;
  height: 200px;
}

.team-no-image-small {
  width: 80px;
  height: 80px;
}

.team-card {
  width: 250px;
  min-height: 350px;
}

.team-card .media {
  height: 140px;
  /* background-size: contain; */
}

.team-card .phone-mail {
  font-size: 0.8rem;
}

.blog-index-thumb {
  width: 80px;
  height: 80px;
}

.blog-form-thumb {
  width: 200px;
  height: 200px;
  margin-left: 6px;
}

.blog-image-btn {
  width: 200px;
}

.price-item-container > .container {
  padding-left: 0px;
  padding-right: 0px;
}

.price-item {
  width: 100%;
}

.gallery-card {
  min-width: 300px;
}

.gallery-thumb {
  height: 200px;
  background-position: top !important;
}

.gallery-title-control {
  width: 100%;
}

.ordination-item-container > .container {
  padding-left: 0px;
  padding-right: 0px;
}

.ordination-item {
  width: 100%;
}

.ordination-empty-item {
  width: 42px;
}

.ordination-cbx-row {
  margin-top: -12px;
}

.ordination-cbx > span {
  font-size: 0.85rem;
  font-weight: 600;
  padding-right: 8px;
}

.login-button {
  width: 100%;
}

.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.login-control-item {
  min-width: 450px;
  max-width: 500px;
}

.login-avatar {
  background-color: #4285f4 !important;
  font-size: 2rem !important;
  width: 80px !important;
  height: 80px !important;
}

@media only screen and (max-width: 768px) {
  .login-control-item {
    min-width: 200px;
    max-width: 300px;
  }

  .login-avatar {
    font-size: 1.5rem !important;
    width: 60px !important;
    height: 60px !important;
  }
}

.select-outlined {
  /* margin: 16px;
  min-width: 120px; */
  height: 40px;
}

/*************** dragable menu item ******/
.dragable-item {
  cursor: grab;
}

.dragable-menu-item a,
.dragable-menu-item-secondary a {
  color: white;
}

.dragable-menu-item,
.dragable-menu-item-secondary {
  cursor: grab;
  width: 100%;
  border-style: none;
  height: 50px;
}

.dragable-menu-item-secondary {
  background-color: #6c757d !important;
  max-width: 480px;
}

.dragable-menu-item {
  background-color: #495057 !important;
  max-width: 500px;
}

/*************** Section editor accordination ******/
.accordination-item {
  cursor: pointer;
  height: 40px;
  padding-top: 8px;
  vertical-align: middle;
}
.accordination-item:hover {
  background-color: #eceeef !important;
  /* outline: 2px solid #d1d5d8;   */
}

/*table styling*/

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  min-width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  /* overflow-x: auto;
  overflow-y: hidden; */
}

.styled-table thead tr {
  background-color: #f8f9fa;
  color: black;
  text-align: left;
}

.styled-table th {
  font-weight: 600;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
  min-width: 80px;
}

/* logout */
.navbar-logout-link {
  color: white;
  /*font-weight: 500 !important;*/
}

.navbar-logout-link:hover {
  color: #e91e63 !important;

  text-decoration: none;
  font-weight: 300 !important;
}

/************************************
smippety - pro pouziti
************************************/

/*xs*/
@media (max-width: 576px) {
}

/*sm*/
@media (min-width: 576px) {
}

/*md*/
@media (min-width: 768px) {
}

/*lg*/
@media (min-width: 992px) {
}

/*xl*/
@media (min-width: 1200px) {
}
